import React from "react"
import { loadStripe } from "@stripe/stripe-js"
import {Link, classNames} from '../utils';


const stripePromise = loadStripe("pk_live_51Hhh5rEblWFgsaroKjFns5BFruX9E64jNTkD2k3Gr1CpcTBY6moSUncpiXHYO5tP4ZEtPebjlXqbqFLKvM0Muc2400fI8ku5IS")

var createCheckoutSession = function(priceId) {
  return fetch("https://stripe.prod.accountantsync.com/create-checkout-session", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      priceId: priceId
    })
  }).then(handleFetchResult);
};

var handleFetchResult = function(result) {
  if (!result.ok) {
    return result.json().then(function(json) {
      if (json.error && json.error.message) {
        throw new Error(result.url + ' ' + result.status + ' ' + json.error.message);
      }
    }).catch(function(err) {
      console.log(err);
      throw err;
    });
  }
  return result.json();
};

export const CheckoutButton = (plan) => {
  let priceId = plan["plan"]["priceId"];
  const redirectToCheckout = async () => {
    console.log("redirectToCheckout")
    const session = await createCheckoutSession(priceId);
    const sessionId = session["sessionId"]
    const stripe = await stripePromise
    stripe.redirectToCheckout({ sessionId })
  }
  if (plan["plan"]["id"] === "free"){
    return <Link to={plan["plan"]["actions"][0]["url"]}
                   target='_blank' rel='noopener'
                   className={classNames('button', 'primary')}>Sign Up</Link>
  }else{
    return <button onClick={redirectToCheckout}>Sign Up</button>
  }
  
}

export default CheckoutButton